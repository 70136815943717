import React from 'react';
import styles from './Loader.module.scss';

export default function Loader({ number, ready }) {
    return (
        <div
            className={[styles.wrapper, ready && styles.hidden]
                .filter(Boolean)
                .join(' ')}
        >
            <img
                className={[styles.img, ready && styles.hiddenNb]
                    .filter(Boolean)
                    .join(' ')}
                src="logo.png"
                alt="logo robin"
            />
            <p
                className={[styles.loader, ready && styles.hiddenNb]
                    .filter(Boolean)
                    .join(' ')}
            >
                {Math.round(number)}%
            </p>
        </div>
    );
}
