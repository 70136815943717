import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Header.module.scss';

export default function Header({ reset }) {
    return (
        <header className={styles.wrapper}>
            <div className={styles.left}>
                <Link
                    to="/"
                    className={styles.logo}
                    onClick={() => reset(true)}
                >
                    <img src="logo.png" alt="logo robin" />
                </Link>
            </div>
            <div className={styles.right}>
                <Link to="/about" className={styles.link}>
                    About
                </Link>
                <a
                    href="mailto:robin.brossard@epitech.eu"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.link}
                >
                    Contact
                </a>
            </div>
        </header>
    );
}
