import React from 'react';
import styles from './ScrollDown.module.scss';

export default function ScrollDown({ swap }) {
    return (
        <div
            data-cursor-hover
            className={styles.wrapper}
            onClick={() => swap(true)}
        >
            <div className={styles.line}></div>
            <div className={styles.line}></div>
            <div className={styles.line}></div>
        </div>
    );
}
