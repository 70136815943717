import React, { Component } from 'react';

import gsap from 'gsap';
import * as THREE from 'three';
import macro from 'raw.macro';

import styles from './Slider.module.scss';

import displaceImage from './displacement.jpg';

const vertexShader = macro('./shaders/vertexShader.glsl');
const fragmentShader = macro('./shaders/fragmentShader.glsl');

const param = {
    border: 1,
    scaleX: 60,
    scaleY: 10,
    width: 0.7,
};

const dataBg = [
    { path: '/projectPreview/lephiltre.png' },
    { path: '/projectPreview/p&c.png' },
    { path: '/projectPreview/jego.png' },
    { path: '/projectPreview/halles.png' },
    { path: '/projectPreview/games.png' },
];

const aspectRatio = 540 / 960;
let w = 960;
let h = 540;

function calcDimension() {
    var width = window.innerWidth;

    if (width <= 1440 && window.innerHeight <= 800) {
        w = 760;
        h = 427;
    }

    if (width <= 1360) {
        w = 760;
        h = 427;
    }

    if (width <= 1150) {
        w = 660;
        h = 371;
    }

    if (width <= 900) {
        w = width - 50;
        h = aspectRatio * width - 50;
    }

    if (width <= 700) {
        w = width;
        h = aspectRatio * width;
    }
}

calcDimension();

class Slider extends Component {
    constructor() {
        super();
        this.limit = 5;
        this.textures = [];
        this.width = w;
        this.height = h;
        this.index = 0;
        this.container = React.createRef();
    }

    componentDidMount = () => {
        window.addEventListener('resize', this.resize);
        this.initScene();
    };

    componentDidUpdate = () => {
        if (this.props.hidden) {
            cancelAnimationFrame(this.raf);
            return;
        }

        if (this.props.index < 1) return;

        let prev = this.index;
        let curr = this.props.index - 1;
        this.index = curr;

        let dir = true;
        if (prev < curr) dir = false;

        this.transitionMaterial.uniforms.texture1.value = this.textures[
            dir ? prev : curr
        ];
        this.transitionMaterial.uniforms.texture2.value = this.textures[
            dir ? curr : prev
        ];
        this.transitionMaterial.uniforms.progress.value = dir ? 0 : 1;
        this.raf = requestAnimationFrame(this.animate);
        this.animateTransition(dir);
    };

    initScene = () => {
        this.renderer = new THREE.WebGLRenderer({ alpha: true });
        this.renderer.setSize(this.width, this.height);
        this.renderer.setPixelRatio(2);
        this.renderer.setClearColor(0xeeeeee, 1);
        this.container.current.append(this.renderer.domElement);
        this.canvas = this.container.current.children[0];
        this.camera = new THREE.PerspectiveCamera(
            70,
            window.innerWidth / window.innerHeight,
            0.001,
            1000
        );
        this.camera.position.set(0, 0, 2);

        this.scene = new THREE.Scene();
        this.scene.background = new THREE.Color(0x000000);

        this.clock = new THREE.Clock();

        let tmpTextures;
        for (let i = 0; i < this.limit; i++) {
            tmpTextures = this.setImageTexture(dataBg[i].path);
            this.textures.push(tmpTextures);
        }

        this.newTransition();
        this.resize();
        this.animate();
    };

    setImageTexture = (path) => {
        var texture = new THREE.TextureLoader().load(path);
        texture.minFilter = THREE.LinearFilter;
        texture.magFilter = THREE.LinearFilter;
        return texture;
    };

    newTransition = () => {
        const displaceTexture = new THREE.TextureLoader().load(displaceImage);
        displaceTexture.minFilter = THREE.LinearFilter;

        let uniform = {
            time: { type: 'f', value: 0 },
            progress: { type: 'f', value: 0 },
            border: { type: 'f', value: param.border },
            scaleX: { type: 'f', value: param.scaleX },
            scaleY: { type: 'f', value: param.scaleY },
            width: { type: 'f', value: param.width },
            texture1: { type: 'f', value: this.textures[0] },
            texture2: { type: 'f', value: this.textures[1] },
            displacement: { type: 'f', value: displaceTexture },
            resolution: { type: 'v4', value: new THREE.Vector4() },
        };

        this.transitionMaterial = new THREE.ShaderMaterial({
            uniforms: uniform,
            vertexShader: vertexShader,
            fragmentShader: fragmentShader,
            side: THREE.DoubleSide,
        });

        var transitionGeo = new THREE.PlaneGeometry(1, 1, 10, 10);

        this.transitionMesh = new THREE.Mesh(
            transitionGeo,
            this.transitionMaterial
        );
        this.scene.add(this.transitionMesh);
    };

    animateTransition = (dir) => {
        if (this.tween) this.tween = undefined;
        if (!this.tween) {
            this.tween = gsap.to(this.transitionMaterial.uniforms.progress, {
                value: dir ? 1 : 0,
                duration: 0.8,
                onComplete: () => {
                    cancelAnimationFrame(this.raf);
                },
            });
        }
    };

    resize = () => {
        calcDimension();
        this.width = w;
        this.height = h;
        this.camera.aspect = this.width / this.height;
        this.imageAspect = this.height / this.width;

        this.transitionMaterial.uniforms.resolution.value.x = this.width;
        this.transitionMaterial.uniforms.resolution.value.y = this.height;
        this.transitionMaterial.uniforms.resolution.value.z =
            (this.width / this.height) * this.imageAspect;
        this.transitionMaterial.uniforms.resolution.value.w = 1;

        const dist = this.camera.position.z;
        this.camera.fov = 2 * (180 / Math.PI) * Math.atan(1 / (2 * dist));

        this.transitionMesh.scale.x = this.width / this.height;
        this.transitionMesh.scale.y = 1;

        this.camera.updateProjectionMatrix();
        this.renderer.setSize(this.width, this.height);
    };

    animate = () => {
        this.renderer.render(this.scene, this.camera);
        this.raf = requestAnimationFrame(this.animate);
    };

    render() {
        return (
            <div ref={this.container} className={styles.webGLContainer}></div>
        );
    }
}

export default Slider;
