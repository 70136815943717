export const data = {
    0: {
        color: '#003683',
        role: 'Developer',
        year: '2020',
        agency: 'Adelios',
        type: 'E-shop',
        link: 'http://lephiltre.com',
    },
    1: {
        color: '#6f1a55',
        role: 'Developer',
        year: '2019',
        agency: 'Adelios',
        type: 'Showcase',
        link: 'https://patrimoine-commerce.com',
    },
    2: {
        color: '#243d65',
        role: 'Developer',
        year: '2020',
        agency: 'Adelios',
        type: 'Showcase',
        link: 'http://jego.preprod.adelios.fr',
    },
    3: {
        color: '#ad043b',
        role: 'Developer',
        year: '2020',
        agency: 'Adelios',
        type: 'Showcase',
        link: 'http://hallestrottemant.preprod.adelios.fr',
    },
    4: {
        color: '#4c4634',
        role: 'Developer',
        year: '2019',
        agency: 'Adelios',
        type: 'Showcase',
        link: 'http://duval.preprod.adelios.fr',
    },
};

export const dataTitle = {
    0: 'Le philtre',
    1: 'Patrimoine & commerce',
    2: 'Jégo',
    3: 'Les halles trottemant',
    4: 'Groupe Duval',
};
