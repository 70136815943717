import React from 'react';
import styles from './TitleSlide.module.scss';
export default function Counter({ index, dataTitle }) {
    return (
        <div className={styles.wrapper}>
            <div className={styles.index}>
                <h2
                    className={[index > 1 && styles.hideTop]
                        .filter(Boolean)
                        .join(' ')}
                >
                    {dataTitle[0]}
                </h2>
                <h2
                    className={[
                        index > 2 && styles.hideTop,
                        index < 2 && styles.hideBot,
                    ]
                        .filter(Boolean)
                        .join(' ')}
                >
                    {dataTitle[1]}
                </h2>
                <h2
                    className={[
                        index > 3 && styles.hideTop,
                        index < 3 && styles.hideBot,
                    ]
                        .filter(Boolean)
                        .join(' ')}
                >
                    {dataTitle[2]}
                </h2>
                <h2
                    className={[
                        index > 4 && styles.hideTop,
                        index < 4 && styles.hideBot,
                    ]
                        .filter(Boolean)
                        .join(' ')}
                >
                    {dataTitle[3]}
                </h2>
                <h2
                    className={[
                        index > 5 && styles.hideTop,
                        index < 5 && styles.hideBot,
                    ]
                        .filter(Boolean)
                        .join(' ')}
                >
                    {dataTitle[4]}
                </h2>
            </div>
        </div>
    );
}
