import React, { useState, useEffect } from 'react';

import MainContent from '../../components/MainContent/MainContent';
import WorkSlider from '../../components/WorkSlider/WorkSlider';
import SocialMedia from '../../components/SocialMedia/SocialMedia';

export default function Main({ ready, softReset, reset }) {
    const [hideMain, setHideMain] = useState(false);

    useEffect(() => {
        if (softReset) {
            setHideMain(false);
            reset(false);
        }
    }, [softReset, reset, hideMain]);

    useEffect(() => {
        var startPos = 0;
        function handleScroll(e) {
            if (Math.sign(e.deltaY) >= 1) {
                if (!hideMain) setHideMain(true);
            }
        }
        function handleTouchStart(e) {
            startPos = e.touches[0].clientY;
        }
        function handleTouchEnd(e) {
            let endPos = e.changedTouches[0].clientY;
            if (endPos + 50 < startPos) {
                if (!hideMain) setHideMain(true);
            }
        }
        window.addEventListener('wheel', handleScroll);
        window.addEventListener('touchstart', handleTouchStart);
        window.addEventListener('touchend', handleTouchEnd);
        return () => {
            window.removeEventListener('wheel', handleScroll);
            window.removeEventListener('touchstart', handleTouchStart);
            window.removeEventListener('touchend', handleTouchEnd);
        };
    }, [hideMain]);

    return (
        <>
            <main>
                <MainContent
                    ready={ready}
                    hidden={hideMain}
                    swap={setHideMain}
                />
                <WorkSlider hidden={!hideMain} swap={setHideMain} />
            </main>
            <SocialMedia hidden={!hideMain} />
        </>
    );
}
