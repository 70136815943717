import React from 'react';
import SocialMedia from '../../components/SocialMedia/SocialMedia';
import styles from './About.module.scss';
export default function About() {
    return (
        <section className={styles.wrapper}>
            <div className={styles.fixed}></div>
            <article className={styles.content}>
                <h1>About</h1>
                <div className={styles.information}>
                    <p className={styles.text}>
                        I'm Robin Brossard, French student in IT living in
                        Paris, currently studying at Epitech and looking for an
                        apprenticeship contract for january.
                    </p>
                    <br />
                    <p className={styles.text}>
                        I like building immersive experiences on the web. I'm
                        always looking forward to learn new things, currently in
                        love with three.js !
                    </p>
                </div>

                <div className={styles.skills}>
                    <h2> Skills </h2>
                    <p className={styles.line}>
                        html sass javascript react three.js gsap
                    </p>
                    <p className={styles.line}>
                        php nodejs mongodb graphql mysql
                    </p>
                </div>
            </article>
            <SocialMedia about={true} />
        </section>
    );
}
