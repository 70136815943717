import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import Header from './components/Header/Header';
import Noise from './components/Noise/Noise';
import Cursor from './components/Cursor/Cursor';
import Main from './pages/Main/Main';
import About from './pages/About/About';
import Loader from './components/Loader/Loader';
import Model from './data/opti.glb';
import './styles/style.scss';

const manager = new THREE.LoadingManager();
const loader = new GLTFLoader(manager);

export var crystal;

function App() {
    const [softReset, setSoftReset] = useState(false);
    const [loadNumber, setLoadNumber] = useState(0);
    const [ready, setReady] = useState(false);

    const loadModel = () => {
        manager.onLoad = function () {
            setReady(true);
        };
        loader.load(
            Model,
            (gltf) => {
                crystal = gltf;
            },
            (xhr) => {
                var percentComplete = (xhr.loaded / 2645204) * 100;
                setLoadNumber(percentComplete);
            }
        );
    };

    useEffect(() => {
        loadModel();
    }, []);

    return (
        <Router>
            {window.innerWidth >= 1024 && <Cursor />}
            <Loader number={loadNumber} ready={ready} />
            <Header reset={setSoftReset} />
            <Noise />
            <Switch>
                <Route path="/about" component={About} />
                <Route path="/">
                    <Main
                        ready={ready}
                        softReset={softReset}
                        reset={setSoftReset}
                    />
                </Route>
            </Switch>
        </Router>
    );
}

export default App;
