import React from 'react';
import styles from './Noise.module.scss';

import Video from './gif.mp4';
export default function Noise() {
    return (
        <div className={styles.noise}>
            <video autoPlay loop muted playsInline>
                <source src={Video} type="video/mp4" />
            </video>
        </div>
    );
}
