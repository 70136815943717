import React, { useEffect, useState, useRef } from 'react';

import Counter from '../Counter/Counter';
import TitleSlide from '../TitleSlide/TitleSlide';
import Slider from '../Slider/Slider';
import styles from './WorkSlider.module.scss';
import { data, dataTitle } from '../../data/data';
import InfosSlide from '../InfosSlide/InfosSlide';

const limit = 5;

export default function WorkSlider({ hidden }) {
    const [count, setCount] = useState(1);
    const transiting = useRef(false);
    const nb = useRef(1);
    const overlayBg = useRef();
    const autoSlide = useRef();
    const autoDir = useRef(true);

    function setTransition(int) {
        transiting.current = true;
        nb.current = nb.current - int;
        setCount(nb.current);
        setTimeout(() => {
            transiting.current = false;
        }, 1000);
    }

    function startAutoSlide() {
        clearInterval(autoSlide.current);
        autoSlide.current = setInterval(() => {
            if (nb.current === 5) autoDir.current = false;
            if (nb.current === 1 && !autoDir.current) autoDir.current = true;
            if (autoDir.current) setTransition(-1);
            else setTransition(1);
        }, 5000);
    }

    useEffect(() => {
        overlayBg.current.style.background = data[count - 1].color;
    }, [count]);

    useEffect(() => {
        var startPos = 0;
        function handleScroll(e) {
            if (transiting.current) return;
            if (Math.sign(e.deltaY) >= 1) {
                if (nb.current < limit) {
                    setTransition(-1);
                    startAutoSlide();
                }
            } else {
                if (nb.current > 1) {
                    setTransition(1);
                    startAutoSlide();
                }
            }
        }
        function handleTouchStart(e) {
            if (transiting.current) return;
            startPos = e.touches[0].clientY;
        }
        function handleTouchEnd(e) {
            if (transiting.current) return;
            let endPos = e.changedTouches[0].clientY;

            if (endPos + 20 < startPos) {
                if (nb.current < limit) {
                    setTransition(-1);
                    startAutoSlide();
                }
            } else if (endPos - 20 > startPos) {
                if (nb.current > 1) {
                    setTransition(1);
                    startAutoSlide();
                }
            }
        }

        if (!hidden) {
            startAutoSlide();
            setTimeout(() => {
                window.addEventListener('wheel', handleScroll);
                window.addEventListener('touchstart', handleTouchStart);
                window.addEventListener('touchend', handleTouchEnd);
            }, 700);
        }
        return () => {
            clearInterval(autoSlide.current);
            window.removeEventListener('wheel', handleScroll);
            window.removeEventListener('touchstart', handleTouchStart);
            window.removeEventListener('touchend', handleTouchEnd);
        };
        // eslint-disable-next-line
    }, [hidden]);

    return (
        <div className={styles.wrapper}>
            <div className={styles.content}>
                <TitleSlide index={count} dataTitle={dataTitle} />
                <div className={styles.row}>
                    <InfosSlide index={count} data={data} />
                    <a
                        className={styles.box}
                        href={data[count - 1].link}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <div className={styles.overlay}>
                            <div
                                ref={overlayBg}
                                className={styles.background}
                            ></div>
                            <p>view</p>
                        </div>
                        <Slider hidden={hidden} index={count} />
                    </a>
                    <Counter index={count} />
                </div>
            </div>
        </div>
    );
}
