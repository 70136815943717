import React, { useEffect, useRef } from 'react';
import ScrollDown from '../../components/ScrollDown/ScrollDown';
import Crystal from '../../components/Crystal/Crystal';
import styles from './MainContent.module.scss';

export default function MainContent({ ready, hidden, swap }) {
    const filter = useRef();

    useEffect(() => {
        setTimeout(() => {
            if (filter.current)
                filter.current.style.transitionDelay = hidden ? '0.9s' : '0s';
        }, 800);
    }, [hidden]);

    return (
        <section
            className={[styles.content, hidden && styles.hidden]
                .filter(Boolean)
                .join(' ')}
        >
            <p> Robin Brossard </p>
            <h1> Fullstack Developer </h1>
            <p> Portfolio 2020 </p>

            <ScrollDown swap={swap} />
            {ready && <Crystal hidden={hidden} />}
            <button onClick={swap}> Scroll down </button>
            <div
                ref={filter}
                className={[styles.window, hidden && styles.show]
                    .filter(Boolean)
                    .join(' ')}
            ></div>
        </section>
    );
}
