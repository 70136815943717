import React from 'react';

import Github from './icons/github.png';
import LinkedIn from './icons/linkedIn.png';
import Mail from './icons/email.png';

import styles from './SocialMedia.module.scss';

export default function SocialMedia({ hidden, about }) {
    if (hidden) return <></>;

    return (
        <footer
            className={[!about ? styles.fixed : styles.footer]
                .filter(Boolean)
                .join(' ')}
        >
            <div className={styles.medias}>
                <a
                    href="https://github.com/robinbrsd"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img src={Github} alt="github icon" />
                </a>

                <a
                    href="https://www.linkedin.com/in/RobinBrsd"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img src={LinkedIn} alt="LinkedIn icon" />
                </a>
                <a
                    href="mailto:robin.brossard@epitech.eu"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img src={Mail} alt="Mail icon" />
                </a>
            </div>
        </footer>
    );
}
