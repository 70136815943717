import React from 'react';
import styles from './Counter.module.scss';
export default function Counter({ index }) {
    return (
        <div className={styles.count}>
            0
            <div className={styles.index}>
                <span
                    className={[index > 1 && styles.hideTop]
                        .filter(Boolean)
                        .join(' ')}
                >
                    1
                </span>
                <span
                    className={[
                        index > 2 && styles.hideTop,
                        index < 2 && styles.hideBot,
                    ]
                        .filter(Boolean)
                        .join(' ')}
                >
                    2
                </span>
                <span
                    className={[
                        index > 3 && styles.hideTop,
                        index < 3 && styles.hideBot,
                    ]
                        .filter(Boolean)
                        .join(' ')}
                >
                    3
                </span>
                <span
                    className={[
                        index > 4 && styles.hideTop,
                        index < 4 && styles.hideBot,
                    ]
                        .filter(Boolean)
                        .join(' ')}
                >
                    4
                </span>
                <span
                    className={[
                        index > 5 && styles.hideTop,
                        index < 5 && styles.hideBot,
                    ]
                        .filter(Boolean)
                        .join(' ')}
                >
                    5
                </span>
            </div>
        </div>
    );
}
