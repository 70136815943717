import React, { useEffect, useState } from 'react';
import styles from './InfosSlide.module.scss';

export default function InfosSlide({ index, data }) {
    const [transition, setTransition] = useState(false);
    const [lastIndex, setLastIndex] = useState(1);

    useEffect(() => {
        if (!transition) setTransition(true);

        let timeOut1 = setTimeout(() => {
            setLastIndex(index - 1);
        }, 200);

        let timeOut2 = setTimeout(() => {
            setTransition(false);
        }, 800);

        return () => {
            clearTimeout(timeOut1);
            clearTimeout(timeOut2);
        };
        // eslint-disable-next-line
    }, [index]);

    return (
        <div className={styles.infos}>
            <div className={styles.block}>
                <p className={styles.item}> Role </p>
                <div className={styles.field}>
                    <p> {data[lastIndex].role} </p>
                    <div
                        className={[styles.window, transition && styles.hidden]
                            .filter(Boolean)
                            .join(' ')}
                    ></div>
                </div>
            </div>
            <div className={styles.block}>
                <p className={styles.item}> Year </p>
                <div className={styles.field}>
                    <p> {data[lastIndex].year} </p>
                    <div
                        className={[styles.window, transition && styles.hidden]
                            .filter(Boolean)
                            .join(' ')}
                    ></div>
                </div>
            </div>
            <div className={styles.block}>
                <p className={styles.item}> Agency </p>
                <div className={styles.field}>
                    <p> {data[lastIndex].agency} </p>
                    <div
                        className={[styles.window, transition && styles.hidden]
                            .filter(Boolean)
                            .join(' ')}
                    ></div>
                </div>
            </div>
            <div className={styles.block}>
                <p className={styles.item}> Type </p>
                <div className={styles.field}>
                    <p> {data[lastIndex].type} </p>
                    <div
                        className={[styles.window, transition && styles.hidden]
                            .filter(Boolean)
                            .join(' ')}
                    ></div>
                </div>
            </div>
        </div>
    );
}
