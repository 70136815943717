import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { ReactComponent as Circle } from './cursor.svg';

import styles from './Cursor.module.scss';

export default function Cursor() {
    const { pathname } = useLocation();
    const [isHover, setHover] = useState(false);
    var raf = useRef();
    var cursor = useRef();
    var pos = useRef();
    var clientX = useRef(-100);
    var clientY = useRef(-100);

    const onmousemove = (e) => {
        clientX.current = e.clientX;
        clientY.current = e.clientY;
    };

    const render = useCallback(() => {
        pos.current.style.transform = `translate(${clientX.current}px, ${clientY.current}px )`;
        raf.current = requestAnimationFrame(render);
    }, []);

    const getHoverEl = () => {
        let elements = document.querySelectorAll(
            'button, a, [data-cursor-hover]'
        );

        elements.forEach((el) => {
            el.addEventListener('mouseenter', () => setHover(true));
            el.addEventListener('mouseleave', () => setHover(false));
        });
    };

    useEffect(() => {
        getHoverEl();
    }, [pathname]);

    useEffect(() => {
        raf.current = requestAnimationFrame(render);
        getHoverEl();
        return () => {
            cancelAnimationFrame(raf.current);
        };
    }, [isHover, render]);

    useEffect(() => {
        document.addEventListener('mousemove', onmousemove);

        return () => {
            cancelAnimationFrame(raf.current);
            document.removeEventListener('mousemove', onmousemove);
        };
    }, []);

    return (
        <div ref={pos} className={styles.position}>
            <Circle
                ref={cursor}
                className={[styles.cursor, isHover && styles.cursorHover]
                    .filter(Boolean)
                    .join(' ')}
            />
        </div>
    );
}
