import React, { Component } from 'react';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { crystal } from '../../App';

import styles from './Crystal.module.scss';

class Crystal extends Component {
    constructor(props) {
        super(props);
        this.crystal = crystal;
        this.manager = new THREE.LoadingManager();
        this.loader = new GLTFLoader(this.manager);
        this.clock = new THREE.Clock();
        this.container = React.createRef();
    }

    componentDidMount = () => {
        window.addEventListener('resize', this.resize);
        this.initScene();
        this.setModel();
    };

    componentDidUpdate = () => {
        if (this.props.hidden) {
            this.stop = setTimeout(() => {
                cancelAnimationFrame(this.raf);
            }, 700);
        } else {
            if (this.stop) clearTimeout(this.stop);
            this.raf = requestAnimationFrame(this.animate);
        }
    };

    componentWillUnmount = () => {
        this.canvas.parentNode.removeChild(this.canvas);
        window.removeEventListener('resize', this.updateDimensions);
        cancelAnimationFrame(this.raf);
    };

    initScene = () => {
        this.renderer = new THREE.WebGLRenderer({ alpha: true });
        this.renderer.setSize(window.innerWidth, window.innerHeight);
        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.setClearColor(0x000000, 0);
        this.container.current.append(this.renderer.domElement);
        this.canvas = this.container.current.children[0];
        this.camera = new THREE.PerspectiveCamera(
            70,
            window.innerWidth / window.innerHeight,
            0.001,
            1000
        );
        this.controls = new OrbitControls(
            this.camera,
            this.renderer.domElement
        );
        this.controls.enableZoom = false;
        this.controls.enableDamping = true;
        this.controls.enablePan = false;
        if (window.innerWidth <= 700) {
            this.controls.minAzimuthAngle = -1;
            this.controls.maxAzimuthAngle = 1;
        } else {
            this.controls.minAzimuthAngle = -2;
            this.controls.maxAzimuthAngle = 2;
        }

        this.controls.minPolarAngle = Math.PI / 2;
        this.controls.maxPolarAngle = Math.PI / 2;

        this.camera.position.z = 10;
        this.scene = new THREE.Scene();
        this.controls.update();
        this.resize();
        this.animate();
    };

    setModel = () => {
        if (window.innerWidth <= 700) {
            this.crystal.scene.scale.set(9, 9, 9);
        } else {
            this.crystal.scene.scale.set(15, 15, 15);
        }
        this.crystal.scene.position.set(1, 0, -10);

        if (window.innerWidth <= 650) {
            let box = new THREE.Box3().setFromObject(this.crystal.scene);
            let center = new THREE.Vector3();
            box.getCenter(center);
            this.crystal.scene.position.sub(center);
            this.crystal.scene.rotation.y = Math.PI;
            this.crystal.scene.position.z = -10;
        }

        this.scene.add(this.crystal.scene);
    };

    resize = () => {
        if (window.innerWidth <= 700) {
            if (this.crystal) this.crystal.scene.scale.set(10, 10, 10);
        }
        this.renderer.setSize(window.innerWidth, window.innerHeight);
        this.camera.aspect = window.innerWidth / window.innerHeight;
        this.camera.updateProjectionMatrix();
    };

    animate = () => {
        if (this.crystal) {
            this.crystal.scene.rotation.y += 0.0009;
            this.crystal.scene.rotation.z += 0.0002;
        }

        this.controls.update();
        this.renderer.render(this.scene, this.camera);
        this.raf = requestAnimationFrame(this.animate);
        if (this.props.hidden || window.location.pathname.includes('/about')) {
            this.stop = setTimeout(() => {
                cancelAnimationFrame(this.raf);
            }, 700);
        }
    };

    render() {
        return (
            <div ref={this.container} className={styles.webGLContainer}></div>
        );
    }
}

export default Crystal;
